<template>
  <div id="mainColumn1">
    <story-list-panel :stories="stories"></story-list-panel>
  </div>
</template>

<script>
  import StoryListPanel from '@/components/news-network/StoryListPanel.vue';
  import { STORIES } from '@/stories/index.ts';

  export default {
    computed: {
      stories() {
        return this.storyIDs.map((id) => STORIES[id]);
      },
    },
    data() {
      return {
        storyIDs: [
          354041, 343111, 312867, 311430, 300286, 281614.5, 281614, 272833,
          248213, 232539, 226072, 221651, 216922, 208868, 200922, 187068, 148797,
          141328, 136728,
        ],
      }
    },
    components: {
      StoryListPanel,
    }
  }
</script>
